<script setup lang="ts">
    import { computed, ref, watch, onMounted, onBeforeUnmount, onBeforeMount } from 'vue';
    // @ts-ignore
    import { useI18n } from 'vue-i18n';
    import DefaultVue from '@/views/layouts/Default.vue';
    import Link from '@/views/components/Link.vue'
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide} from 'vue3-carousel'
    import useNavigationStore from '@/stores/ui/navigation.ts';
    import SavComponent from '@/views/components/SavComponent.vue';

    import TopelaVideoPlayer from '../(public)/components/TopelaVideoPlayer.vue'
    import LauchingOfferAndDowloadApps from '../(public)/components/LauchingOfferAndDowloadApps.vue'
    import {usePageContext} from "vike-vue/usePageContext";
    import {navigate} from "vike/client/router";
    // import { useRoute } from 'vue-router';

    const pageContext = usePageContext()
    const { t } = useI18n()
    const navigationStore = useNavigationStore()
    // const route = useRoute()

    let activeSlide  = ref(0)
    const slideItemsNumber = 15
    const slideItemsPerPage = ref(5)

    const VITE_ASSET_URL = import.meta.env.VITE_ASSET_URL

    const elem = ref(null)

    watch(() => navigationStore.homeScrollTo, (newId,oldId) => {
        let id = newId ?? oldId
        if (id) {
          const section: any = document.getElementById(id !== "client-user" ? id : id+'-replace')
          if (section) {
            setTimeout(() => {
              section.scrollIntoView({
                behavior: "smooth"
              })
            }, 500)
          }
        }
    });

    watch(elem, (new_value) => {
        if (new_value !== null) {
            if (navigationStore.getHomeScrollTo !== null) {
                let id = navigationStore.getHomeScrollTo
                const section: any = document.getElementById(id !== "client-user" ? id : id+'-replace')
                if (section) {
                    setTimeout(() => {
                        section.scrollIntoView({
                            behavior: "smooth"
                        })
                    }, 500);
                }
            }
        }
    });

    const totalPages = computed(() => Math.ceil(slideItemsNumber / slideItemsPerPage.value));
    const currentPage = computed(() => Math.floor(activeSlide.value / slideItemsPerPage.value) + 1);

    const changePage = (page:number) => {
        activeSlide.value = (page - 1) * slideItemsPerPage.value;
    };

    const scrollToSection = (id: string) => {
        const section = document.getElementById(id !== "client-user" ? id : id+'-replace')
        if (section) {
            setTimeout(() => {
              section.scrollIntoView({
                behavior: "smooth"
              })
              navigationStore.setHeaderScrollTo(id)
              navigationStore.setHomeScrollTo(id)
            }, 500);
        }
    };

    onBeforeMount(async () => {
        if (pageContext.urlPathname != "/") {
          console.log("rendered '/' instead of" + pageContext.urlPathname)
          console.log("navigating to " + pageContext.urlPathname)
          await navigate(pageContext.urlPathname)
          return
        }
    })

    onMounted(async () => {
      if (pageContext.urlPathname != "/") {
          console.log("rendered '/' instead of" + pageContext.urlPathname)
          console.log("navigating to " + pageContext.urlPathname)
          await navigate(pageContext.urlPathname)
          return
      }
      setCapturesItemPerPage()
      redirectToSection()
      window.addEventListener('resize', handleResize);
      if (navigationStore.getHomeScrollTo !== null) {
        let id = navigationStore.getHomeScrollTo
        const section: any = document.getElementById(id !== "client-user" ? id : id+'-replace')
        if (section) {
          setTimeout(() => {
            section.scrollIntoView({
                behavior: "smooth"
            })
            navigationStore.setHomeScrollTo(null)
          }, 500)}
        }
      }

    )
    const handleResize = () => {
      setCapturesItemPerPage()
    };

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    const setCapturesItemPerPage = () =>{
      slideItemsPerPage.value = window.innerWidth <= 950 ? 3: 5
    }

    const redirectToSection = () =>{
      // const parts = route.fullPath.split("#")
      // if (parts.length === 2) {
      //   const id = parts[1]
      //   scrollToSection(id)
      // }
    }
</script>

<template>
    <DefaultVue>

        <header id="home" class="hero-area-2">
            <div class="overlay" hidden></div>
            <!-- ********************** New header content ************* -->
            <div class="hero-bg">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-7 col-xs-12">
                            <div class="hero-light px-3 py-0 pt-3 animate__animated  animate__fadeInLeft animate__slow fadeInLeft h-100" data-wow-delay="0.2s" data-wow-offset="50" >
                                <div class="row h-100">
                                    <div class="col grid text-center">
                                        <h1 class="head-title">
                                            {{  t("w_txt_home_heroHeadingTitle") }}
                                        </h1>

                                        <p class="pb-3" style="font-size: large">
                                            {{ t("w_txt_home_heroHeadingDescription") }}
                                        </p>
                                        <div class="row pt-3 home-bg" :style="{ backgroundImage: 'url(' + VITE_ASSET_URL + 'assets/public/assets/img/features/home-bg.webp' + ')', backgroundSize: 'cover !important' }">
                                            <div :style="{ textAlign:'right', alignContent:'end' }" class="col-6 p-0 animate__animated  animate__fadeInLeft fadeInLeft animate__slow text-right" data-wow-delay="1s" data-wow-offset="10">
                                                <img :src="VITE_ASSET_URL + 'assets/public/assets/img/features/pro-standing-holding-phone-cropped.webp'"
                                                  height="200" alt="Artisan utilisant l'application topela pour gérer ses devis"/>
                                            </div>
                                            <div :style="{ textAlign: 'left', alignContent:'end' }" class="col-6 p-0 animate__animated animate__fadeInRight fadeInRight text-left" data-wow-delay="1s" data-wow-offset="10" >
                                                <img  :src="VITE_ASSET_URL + 'assets/public/assets/img/features/client-standing-cropped.webp'" height="200" alt="Client accédant à son espace client depuis son ordinateur"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none mt-4"></div>
                        <div class="col-lg-5 col-md-5 col-xs-12" style="display: grid">
                            <div class="p-0 hero-art mb-4 animate__animated animate__fadeInRight animate__slow fadeInRight grid align-items-center" data-wow-delay="0.2s" style="position: relative;">
                                <div class="row h-100" style="overflow: hidden;">
                                    <div class="col-md-4 p-0">
                                        <img :src="VITE_ASSET_URL + 'assets/public/assets/img/features/pro-holding-phone.webp'" :style="{
                                            width: '207px',
                                            position: 'absolute',
                                            bottom: '0px',
                                            left: '0px !important',
                                            borderRadius: '0px 0px 0px 15px !important',
                                            zIndex: '-1',
                                            opacity: '0.6 !important'
                                        }" alt="artisan qui communique avec son client"/>
                                    </div>
                                    <div class="col-md-8 p-3">
                                        <h6>{{ t("w_txt_home_heroProHeading") }}</h6>
                                        <p>
                                            {{ t("w_txt_home_heroMobileAppProposion") }}
                                        </p>
                                        <div class="header-button">
                                            <a href="#pro-user-to" @click.prevent="scrollToSection('pro-user-to')" class="btn btn-border-filled page-scroll m-0 mt-2">
                                                {{ t("w_act_discover")}}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-0 hero-clt animate__animated animate__fadeInRight animate__slow fadeInRight grid align-items-center" data-wow-delay="0.2s">
                                <div class="row h-100">
                                    <div class="col-md-8 p-3">
                                        <h6>{{ t("w_txt_home_heroClientHeading") }}</h6>
                                        <p>
                                            {{ t("w_txt_home_heroSiteProposition") }} <br />
                                        </p>
                                        <span style="font-size: x-small">{{ t("w_txt_home_heroComingSoon") }} </span>
                                        <div class="header-button">
                                            <Link id="searchPros" href="/wk/search" class="btn btn-light text-warning fw-bold rounded-pill btn-border-filled2 m-0 mt-2">{{ t('w_act_search') }}</Link>
                                        </div>
                                    </div>
                                    <div class="col-md-4 p-0" style="position: relative;">
                                        <img :src="VITE_ASSET_URL + 'assets/public/assets/img/features/client-in-front-of-computer.webp'" :style="{
                                            width: '207px',
                                            position: 'absolute',
                                            bottom: '0',
                                            right: '10px !important',
                                            transform: 'scaleX(-1)',
                                            borderRadius: '0px 0px 0px 15px !important',
                                            zIndex: '-1',
                                            opacity: '0.6 !important'
                                        }" alt="Le client sollicite des travaux auprès de son artisan"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--***********************End New header content ***********-->
        </header>

        <!-- ************************************************************ New Content **************************************************************************** -->

        <!-- features Section Start -->
        <div id="app-features" class="section" style="padding-bottom: 0px !important;padding-top: 100px !important;">
          <div class="container">
            <div class="section-header mb-0">
              <h1 class="section-title animate__animated animate__fadeIn animate__slow fadeIn" data-wow-delay="0.2s">
                {{ t("w_txt_home_featuresTopelaDescription") }}
              </h1>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-12 col-xs-12 p-0">
                <div class="content-left w-100">
                  <div class="box-item left">
                    <span class="icon">
                      <i class="material-icons">waving_hand</i>
                    </span>
                    <div class="text" style="text-align: right">
                      <h4>{{ t("w_txt_home_featuresWithinEasyReach") }} </h4>
                      <p>
                        {{ t("w_txt_home_featuresProOnMobile") }} <br />
                        {{ t("w_txt_home_featuresClientOnWebSite") }}
                      </p>
                    </div>
                  </div>
                  <div class="box-item left">
                    <span class="icon">
                      <i class="material-icons">calendar_month</i>
                    </span>
                    <div class="text" style="text-align: right">
                      <h4>{{ t("w_txt_home_featuresWhenINeed") }}</h4>
                      <p>
                        {{ t("w_txt_home_featuresServicesOnEveryday") }}<br />{{ t("w_txt_home_featuresRealTimeDataShare") }}
                      </p>
                    </div>
                  </div>
                  <div class="box-item left">
                    <span class="icon">
                      <i class="material-icons">support_agent</i>
                    </span>
                    <div class="text" style="text-align: right">
                      <h4>{{ t("w_txt_home_featuresSupportAvailable")}}</h4>
                      <p>
                        {{ t("w_txt_home_featuresAfterSalesServices") }} <br />{{ t("w_txt_home_featuresAfterSalesServicesTimes") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-xs-12 d-flex flex-wrap justify-content-center align-content-center">
                <div class="show-box m-0 video-thumbnail w-100" style="min-height: 200px" :style="{ backgroundImage: 'url(' + VITE_ASSET_URL + 'assets/public/assets/img/features/video-snapshot.webp' + ')', backgroundSize: 'cover !important' }">
                    <TopelaVideoPlayer />
                </div>
                <div>
                  <h5 class="mt-3 animate__animated zoomIn" data-wow-duration="1000ms" data-wow-delay="100ms">
                    {{ t("w_txt_home_featuresTopelaInImage") }}
                  </h5>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-xs-12 p-0">
                <div class="content-right w-100">
                  <div class="box-item right">
                    <span class="icon">
                      <i class="material-icons">shield</i>
                    </span>
                    <div class="text">
                      <h4>{{ t("w_txt_home_featuresSecuredExchanges") }}</h4>
                      <p>{{ t("w_txt_home_featuresSecuredExchangesDescription") }}</p>
                    </div>
                  </div>
                  <div class="box-item right">
                    <span class="icon">
                      <i class="material-icons">construction</i>
                    </span>
                    <div class="text">
                      <h4>{{ t("w_txt_home_featuresUseful") }}</h4>
                      <p>{{ t("w_txt_home_featuresUsefulDescription") }} </p>
                    </div>
                  </div>
                  <div class="box-item right">
                    <span class="icon">
                      <i class="material-icons">forum</i>
                    </span>
                    <div class="text">
                      <h4>{{ t("w_txt_home_featuresDynamic") }}</h4>
                      <p>{{ t("w_txt_home_featuresDynamicMessaging") }} </p>
                      <p>{{ t("w_txt_home_featuresDynamicNotification") }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- features Section End -->

        <div id="pro-user-to" style="height: 80px;background-color:#f6fbfd !important;"></div>
        <!-- Start Video promo Section -->
        <div id="pro-user" class="video-promo art py-5">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-sm-12 mb-0">
                <div class="video-promo-content text-center m-0">
                  <h2>{{ t("w_txt_home_heroProHeading") }}</h2>
                  <p>{{ t("w_txt_home_prouserTopelaDescription") }} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Video Promo Section -->

        <!-- Awesome Screens Section Start -->
        <div id="screenshots" class="screens-shot py-5" style="padding-bottom: 5px !important;">
          <div class="container">
            <div class="section-header mb-1">
              <h2 class="section-title">{{ t("w_txt_home_prouserOfficeInPocket") }}</h2>
              <p class="btn btn-subtitle animate__animated fadeInDown" data-wow-delay="0.2s">
                <a class="nav-link page-scroll" style="color: #3c9cfd !important;" href="#info-app-to" @click.prevent="scrollToSection('info-app-to')">
                    {{ t("w_act_home_prouserDownloadApp") }}
                </a>
              </p>
            </div>

            <div class="row">
                <Carousel :autoplay="2000" :wrap-around="true" :itemsToShow="slideItemsPerPage" :transition="500" class="custom-carousel" v-model="activeSlide">
                    <Slide v-for="slide in slideItemsNumber"  :key="slide">
                        <div class="carousel__item">
                            <div class="item">
                                <div class="screenshot-thumb">
                                    <img
                                    class="img-fluid img-rounded"
                                    :src="VITE_ASSET_URL + `assets/public/assets/img/screenshot/img-${slide}.webp`"
                                    alt="Captures d'écran de l'application topela"/>
                                </div>
                            </div>
                        </div>
                    </Slide>

                    <template #addons>
                        <div class="custom-pagination">
                            <div v-for="(index) in totalPages" :key="index" class="pagination-dot" :class="{ active: index === currentPage }" @click="changePage(index)"></div>
                        </div>
                    </template>
                </Carousel>
            </div>
          </div>
        </div>
        <!-- Awesome Screens Section End -->

        <!-- Launching offer and download app -->
        <LauchingOfferAndDowloadApps />

        <!-- Start Video promo Section -->
        <div id="client-user" class="video-promo clt py-4">
          <div class="overlay"></div>
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="video-promo-content text-center m-0">
                  <h2>{{ t("w_txt_home_heroClientHeading") }}</h2>
                  <p>
                    {{ t("w_txt_home_clientuserCustomerPrivateArea") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Video Promo Section -->

        <!-- Features Section Start -->
        <div class=" py-5" style="padding-bottom:10px!important">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="text-wrapper animate__animated animate__fadeInRight animate__slow fadeInRight" data-wow-delay="0.9s">
                  <div>
                    <h3 style="color: #f0a62f">
                      {{ t("w_txt_home_clientuserIManagePrivateSpace") }}
                    </h3>
                    <h5>{{ t("w_txt_home_clientuserItsFree") }} </h5>
                    <div class="px-5">
                      <ul>
                        <li>&#10003; {{ t("w_txt_home_clientuserScherachPro") }}</li>
                        <li>
                          &#10003; {{ t("w_txt_home_clientuserContactProViaMessage") }}
                        </li>
                        <li>
                          &#10003; {{ t("w_txt_home_clientuserViewAndSignElectronically") }}
                        </li>
                        <li>&#10003; {{ t("w_txt_home_clientuserKeepWorksTrack") }}</li>
                        <li>&#10003; {{ t("w_txt_home_clientuserReceiveAndManageInvoices") }}</li>
                        <li>
                          &#10003; {{ t("w_txt_home_clientuserDownloadPDF") }}
                        </li>
                      </ul>
                    </div>
                    <div class="row justify-content-center p-3 mt-5">
                      <p
                        class="btn btn-subtitle2 animate__animated animate__slow fadeInDown w-75"
                        data-wow-delay="0.2s"
                        style="background-color: #f0a62f50;"
                      >
                        <Link
                          class="nav-link page-scroll pt-1 pb-1"
                          style="color: #f0a62f"
                          href="/auth/signup"
                          >{{ t("w_act_home_clientuserRegisterAsIndividual") }} </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="img-thumb fadeInLeft" data-wow-delay="1.2s">
                  <img
                    class="img-fluid"
                    :src="VITE_ASSET_URL + 'assets/public/assets/img/features/client-img2.webp'"
                    alt="Page d'accueil de l'espace client sur le site web 123topela.fr"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Features Section End -->

        <!-- Contact Section Start -->
        <section id="contact" style="background-color: #f8f8f8;">
          <div class="video-promo py-4">
            <div class="overlay"></div>
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <div class="video-promo-content text-center m-0">
                    <h2>{{ t("w_act_header_contact") }} </h2>
                    <p>
                      {{ t("w_txt_home_ContactUsDescription") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="contact-form mt-5">
            <div class="container">
              <div class="row justify-content-center">
                <div class="pt-4">
                  <div class="col-lg-12 col-md-12 col-xs-12 animate__animated animate__slow animate__fadeInDown">
                    <div class="contact-block pt-5 fadeInUp" v-animate-scroll="'animate__animated animate__slow animate__fadeInUp'" data-wow-delay="0.2s">
                      <div class="mt-2"></div>
                      <SavComponent
                            :id="'home_sav'"
                            :source="t('w_sav_src_contact_form')"
                            :btn-label="t('g_act_send')"
                            :intro="t('w_sav_intro_contact_form')"
                            :btn-classes="['btn-lg', 'rounded-pill', 'btn-common', 'text-white', 'fw-bold', 'btn-effect']"
                            :btn-container-classes="['submit-button', 'd-flex', 'justify-content-center']"
                            :outro="t('w_sav_outro_generic')"
                        >
                      </SavComponent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Contact Section End -->

        <!-- ***************************************************** En of New Content **************************************************************************** -->

    </DefaultVue>
</template>

<style scoped>
/* @import url('@/assets/css/bootstrap-extended2.css'); */
@import url('@/assets/css/bootstrap-extended.css');
@import url('@/assets/css/animate.css');
@import url('@/assets/css/main.css');
@import url('@/assets/css/responsive.css');


.section {
  padding: 120px 0 65px !important;
}

#searchPros:hover, #discover:hover {
    color: white !important;
}

.custom-carousel{
    padding: 0px;
}
.custom-pagination {
  display: flex;
  justify-content: center;
}

.pagination-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  background: #fff; /* Default color for inactive slides */
}

.pagination-dot.active {
  background: #3c9cfd;; /* Blue color for active slide */
}

.img-rounded {
  border-radius: 20px !important;
  box-shadow: 4px 5px 5px gray !important;
}
</style>